import React from 'react'
import { Link } from 'react-router-dom'


const BlogItem = (props) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single-blog single-blog-2 wow fadeInUp" data-wow-delay="300ms" style={{ visibility: "visible", animationDelay: "500ms", animationName: "fadeInUp" }}>
        <div className="blog-img">
          <Link to={`/blog/${props.id}`}><img src={props.image} alt="" /></Link>
          <div className="tj__blog_date text-center">
            <p dangerouslySetInnerHTML={{ __html: props.month }} />
          </div>
        </div>
        <div className="blog-content position-relative">
          <div className="blog__inner blog__inner-2">
            <div className="blog__meta">
              <div className="tjblog_author">
                <i className="fal fa-user-circle"></i> By {props.author}
              </div>
              <div className="tjblog_comment">
                <i className="fa-regular fa-message"></i> 0
              </div>
            </div>
            <div className="content">
              <h3 className="blog-title"><Link to={`/blog/${props.id}`}>{props.title}</Link></h3>
            </div>
            <div className="tj-blog-btn">
              <Link to={`/blog/${props.id}`}>Read More<i className="fa-regular fa-arrow-right"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogItem