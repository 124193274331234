import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'


const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Contact Us - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Contact Us" />

      <div className="contact_info-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="tj-single-info wow fadeInUp " data-wow-delay="300ms">
                <div className="tj-info-head"></div>
                <div className="tj-body">
                  <div className="icon-thumb">
                    <div className="icons"><i className="fa-light fa-phone-volume"></i></div>
                  </div>
                  <div className="content text-center">
                    <h4 className="title">Any Questions</h4>
                    <a href="tel:+919113867704">+91 9113867704</a>
                    <a href="/">&nbsp;</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tj-single-info wow fadeInUp " data-wow-delay="500ms">
                <div className="tj-info-head"></div>
                <div className="tj-body">
                  <div className="icon-thumb">
                    <div className="icons"><i className="fa-light fa-envelope-open"></i></div>
                  </div>
                  <div className="content text-center">
                    <h4 className="title">Email Address</h4>
                    <a href="mailto:contact@planetguard.ai">contact@planetguard.ai</a>
                    <a href="/">&nbsp;</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tj-single-info wow fadeInUp " data-wow-delay="700ms">
                <div className="tj-info-head"></div>
                <div className="tj-body">
                  <div className="icon-thumb">
                    <div className="icons"><i className="fa-light fa-map-marked-alt"></i></div>
                  </div>
                  <div className="content text-center">
                    <h4 className="title">Office Location</h4>
                    <p>Salarpuria Serinity, 7th sector, HSR Layout, Bengaluru, Karnataka 560102</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-wrapper section-bottom">
        <div className="contact-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0088089905607!2d77.63025187583362!3d12.907154916286396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1556d789b071%3A0xc1cc4ec19e7fed3e!2sSalarpuria%20Serenity!5e0!3m2!1sen!2sin!4v1722526004909!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Google Map"></iframe>
        </div>
        <div className="container">
          <div className="contact-widget">
            <div className="row">
              <div className="col-xl-6 offset-xl-3">
                <div className="section__heading text-center">
                  <h2 className="section_title">Have be any question? feel free to Contact</h2>
                </div>
              </div>
            </div>
            <form action="https://planetguard.us17.list-manage.com/subscribe/post?u=da22a802266fd6ee4706af662&amp;id=3abc7c48a9&amp;f_id=00e343e3f0" method="POST" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="contact-form validate">
              <div className="row">
                <div className="col-sm-6">
                  <div className="from-group">
                    <input type="text" className="form-control required" name="FNAME" id="mce-FNAME" placeholder="Name" required />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="from-group">
                    <input type="email" className="form-control required" name="EMAIL" id="mce-EMAIL" placeholder="Email Address" required />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="from-group">
                    <input type="text" className="form-control required" name="PHONE" id="mce-PHONE" placeholder="Phone Number" required />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="from-group">
                    <input type="text" className="form-control required" name="MMERGE6" id="mce-MMERGE6" placeholder="Subject" required />
                  </div>
                </div>
                <div className="col-sm-12 my-2">
                  <div className="from-group">
                    <textarea className="form-control" cols="30" rows="10" name="MMERGE7" id="mce-MMERGE7" placeholder="Message" required></textarea>
                  </div>
                </div>
                <div className="col-sm-12 text-center">
                  <div className="from-group">
                    <button type="submit" className="tj-btn-primary" name="subscribe" id="mc-embedded-subscribe">
                      Submit Now
                      <i className="fa-light fa-arrow-right-long"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default ContactUs