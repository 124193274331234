import React, { useCallback, useEffect, useState } from 'react'
import Papa from 'papaparse'

import MapWildfire from '../components/MapWildfire'
import { Link } from 'react-router-dom'

import logo from '../assets/images/logo.png'


const WildFireApp = () => {
  const [active, setActive] = useState()
  const [schools, setSchools] = useState([])
  const [facilities, setFacilities] = useState([])

  const fetchCSV = (file) => {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(file)
      const reader = response.body.getReader()
      const result = await reader.read()
      const decoder = new TextDecoder('utf-8')
      const csv = decoder.decode(result.value)
      const parsedData = Papa.parse(csv, { header: true })
      resolve(parsedData.data)
    })
  }

  const fetchFacilities = useCallback(() => {
    fetchCSV('/csvs/facilities.csv').then(data => {
      let records = []
      for (const facility of data) {
        if (facility.NAME && facility.LATITUDE && facility.LONGITUDE) {
          records.push({
            name: facility.NAME,
            latitude: facility.LATITUDE,
            longitude: facility.LONGITUDE,
          })
        }
      }
      setFacilities(records)
    })
  }, [])

  const fetchSchools = useCallback(() => {
    fetchCSV('/csvs/schools.csv').then(data => {
      let records = []
      for (const school of data) {
        if (school.DistrictName && school.Latitude && school.Longitude) {
          records.push({
            name: school.DistrictName,
            latitude: school.Latitude,
            longitude: school.Longitude,
          })
        }
      }
      setSchools(records)
    })
  }, [])

  useEffect(() => {
    fetchSchools()
    fetchFacilities()

    window.scrollTo(0, 0)
    document.title = "Wildfire Map - Planet Guard"
  }, [fetchSchools, fetchFacilities])

  const onFilterFacilities = () => {
    setSchools([])
    fetchFacilities()
    setActive('facilities')
  }

  const onFilterSchools = () => {
    setFacilities([])
    fetchSchools()
    setActive('schools')
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-3 col-lg-2 p-0">
          <header className="d-flex align-items-center">
            <div className="logo ms-3">
              <Link to="/"><img src={logo} alt="logo" /></Link>
            </div>
          </header>
          <div className="mt-3">
            <div className="list-group list-group-flush">
              <div className="list-group-item list-group-item-action">CURRENT INCIDENT FIRES</div>
              <button className={`list-group-item list-group-item-action ${active === 'facilities' ? 'active' : ''}`} onClick={onFilterFacilities}>Fire Radiative Powers</button>
              <button className={`list-group-item list-group-item-action ${active === 'schools' ? 'active' : ''}`} onClick={onFilterSchools}>Schools</button>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-9 col-lg-10 p-0">
          <h2 className="map-title">California Wildfire Prediction Webmap</h2>
          <MapWildfire center={[38.664, -120.07]} schools={schools} facilities={facilities} />
        </div>
      </div>
    </div>
  )
}

export default WildFireApp