import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'

import agriGuard from '../assets/images/agriguard.jpg'


const AgriGuard = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Agri Guard - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Agri Guard" />

      <div className="about-wrapper section-padding careers">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 mission">
              <img src={agriGuard} alt="" className="mb-3" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">Welcome to Agriguard: Your Farming Revolution Begins Here</h2>
              </div>
              <p><strong>Farmer Management System:</strong> Streamline your farming operations from start to finish with our
                comprehensive Farmer Management System. Say goodbye to hassle and hello to efficiency as you effortlessly
                purchase top-quality inputs like seeds, fungicides, and pesticides, all in one convenient platform.</p>
              <p><strong>Satellite-based Crop Monitoring System:</strong> Experience farming like never before with our
                state-of-the-art satellite-based crop monitoring system. Receive real-time updates on your crop's health and
                growth, coupled with expert advisory to help you make informed decisions and optimize your yields.</p>
              <p><strong>Crop Yield Prediction:</strong> Unlock the power of precision with our cutting-edge crop yield prediction technology. By leveraging satellite imagery, drone insights, and advanced AI/ML
                algorithms, we provide you with accurate yield forecasts at the field level, empowering you to plan ahead
                with confidence.</p>
            </div>
            <div className="col-lg-12 col-md-12">
              <p><strong>Agridoctor:</strong> Protect your harvest with our AI-powered Agridoctor. Detect pests and diseases
                early on, and take proactive measures to safeguard your crops. With Agriguard, your fields are under
                constant vigilance, ensuring a healthy and thriving harvest.</p>
              <p><strong>Irrigation Management System:</strong> Optimize your water usage with our intelligent irrigation
                management system. Receive satellite and weather-based advisories tailored to your specific needs, ensuring
                efficient water distribution and maximizing crop health.</p>
              <h4>Additional Facilities for Farmers:</h4>
              <ul>
                <li><strong>Carbon Credit Rewards:</strong> Join the sustainability movement and earn rewards for your
                  eco-friendly farming practices. With Agriguard, your efforts towards a greener future are recognized and
                  rewarded.</li>
                <li><strong>Organic Farming Ideas:</strong> Explore a wealth of organic farming techniques and ideas to
                  nurture your soil and cultivate a more sustainable agriculture. Agriguard is your guide to embracing the
                  power of nature in farming.</li>
                <li><strong>Soil Testing Facilities:</strong> Gain invaluable insights into your soil's composition and
                  health with our comprehensive soil testing facilities. Armed with this knowledge, you can make informed
                  decisions about nutrient management, leading to healthier crops and higher yields.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="about-wrapper about-4 section-padding overflow-hidden bg-light careers">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">We have a dedicated app to manage farm. Which provides below features.</h2>
              </div>
              <p><strong>Organic Farming:</strong> AgriGuard supports organic farming by educating farmers on essential
                tools and techniques. Additionally, we offer a range of products including bio fertilizers, bio fungicides,
                bio insecticides, herbal extracts, bio nematicides, and suitable seeds.</p>
              <p><strong>Crop Advisory:</strong> Discover crop-related challenges and access impartial solutions from
                AgriGuard's team of experts.</p>
              <p><strong>Crop Monitoring:</strong> Crop monitoring by utilizing satellite and drone technology enables
                farmers to gain invaluable insights into their fields' health and growth. Understanding the impact of
                weather on crops is essential for successful agriculture, and AgriGuard provides actionable strategies to
                mitigate adverse effects, ensuring optimal crop yield and resilience in the face of environmental
                challenges.</p>
              <p><strong>Input Purchase:</strong> For purchasing inputs like organic fertilizers, pesticides, Insecticide,
                Fungicide, Agri Tools and micronutrients, farmers can browse and buy directly from the app at competitive
                prices. Recommendations are tailored to their chosen crops, simplifying the selection and ordering process.
                Payment options within the app ensure a seamless transaction experience.</p>
              <p><strong>Disease Detection:</strong> Through the "Disease Detection" feature, farmers can snap a clear photo
                of any crop issue and receive a diagnosis and product recommendation within minutes. Alternatively, they can
                select the crop from a dropdown menu or pose their own question to experts. Additionally, farmers receive
                SMS and call notifications for prompt assistance.</p>
              <p><strong>Soil Testing:</strong> Soil testing is made easy with AgriGuard. Farmers can send soil samples for
                analysis and receive detailed reports highlighting deficiencies and actionable insights within a day of
                sample receipt.</p>
              <p><strong>Carbon Credit: </strong> Agri Guard helps both farmers and the environment by facilitating the sale
                of carbon credits from crops and forests. We ensure credibility by following the rigorous VERRA standards
                and utilizing remote sensing data to track carbon capture. Additionally, soil testing confirms these
                observations, providing a comprehensive picture. By bridging the gap between farmers and credit buyers, Agri
                Guard fosters a win-win situation: farmers earn income for sustainable practices, and credit buyers offset
                their carbon footprint.</p>
              <p><strong>Expert Guidance:</strong> Our team of on-the-ground experts and agronomists empowers farmers with a
                suite of practical solutions. We guide them in organic farming practices, identify diseases early to
                minimize losses, and suggest methods to maximize crop yields. By analyzing the latest satellite and weather
                data, we provide expert recommendations on the ideal crops to plant for specific fields and seasons. This
                comprehensive approach ensures farmers not only contribute to a healthier environment but also achieve
                greater success on their land.</p>
              <div className="author-content align-items-center" data-aos="fade-up" data-aos-duration="1000">
                <a href="https://planetguard.ai" className="tj-btn-primary style-1">Get our app from google play store <i
                  className="fa-light fa-arrow-right-long"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default AgriGuard