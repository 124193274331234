import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'


const AgriGuardApp = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Agri Guard - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Agri Guard" />

      <div className="about-wrapper section-padding careers">
        <div className="container">
          <h1>Agri Guard</h1>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default AgriGuardApp