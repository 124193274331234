import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'

import wildFire from '../assets/images/Wild-Fire.jpg'


const WildFire = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Wild Fire - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Wild Fire" />

      <section className="about-wrapper section-padding careers">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-6 mission">
              <img src={wildFire} alt="" className="mb-3" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">Wildfire Prediction</h2>
              </div>

              <h4>The Future of Wildfire Forecast</h4>
              <p>At Planet Guard, we harness cutting-edge geospatial satellite technology and advanced weather modeling to provide you with highly accurate wildfire predictions. Our state-of-the-art system offers 10-meter resolution forecasts, ensuring you get the precise information you need to protect your assets and loved ones.</p>

              <h4>Key Features:</h4>
              <h6><strong>Advanced Risk Assessment Tools</strong></h6>

              <ul>
                <li><strong>Distance-Measuring Tool:</strong> Check your asset's risk with precision. Our intuitive tool
                  allows you to measure the distance from any location to the nearest predicted wildfire zones, helping you
                  make informed decisions.</li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12">
              <h6><strong>Personalized Risk Ratings</strong></h6>
              <ul>
                <li><strong>Location-Based Risk Evaluation:</strong> Simply input your location data, and our product will
                  generate a detailed risk rating for your area. Stay ahead of potential threats with personalized and
                  actionable insights.</li>
              </ul>

              <h6><strong>High-Resolution Forecasting</strong></h6>
              <ul>
                <li><strong>10-Meter Resolution Predictions:</strong> Our system provides forecasts at an unparalleled
                  10-meter resolution, giving you the clearest picture of potential wildfire activity in your vicinity.</li>
              </ul>

              <h6><strong>Extended Forecast Period</strong></h6>
              <ul>
                <li><strong>10-Day Outlook:</strong> Plan ahead with confidence. Our 10-day predictive models ensure you
                  have ample time to take necessary precautions.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrapper about-4 section-padding overflow-hidden bg-light careers">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <h4>How It Works:</h4>
              <ul>
                <li><strong>Input Your Location:</strong> Enter your address or use our interactive map to pinpoint your
                  location.</li>
                <li><strong>Analyze Risk:</strong> Our advanced algorithms analyze satellite data and weather patterns to
                  assess the wildfire risk in your area.</li>
                <li><strong>Receive Your Rating:</strong> Get an easy-to-understand risk rating, along with detailed
                  information on the proximity and potential impact of nearby wildfires.</li>
                <li><strong>Stay Informed:</strong> With daily updates and real-time alerts, you can stay on top of changing
                  conditions and protect what matters most.</li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6">
              <h4>Why Choose Planet Guard?</h4>
              <ul>
                <li><strong>Accuracy:</strong> Leveraging the latest in satellite and weather technology, we provide the
                  most accurate wildfire predictions available.</li>
                <li><strong>User-Friendly Interface:</strong> Our platform is designed with you in mind, offering a seamless
                  and intuitive user experience.</li>
                <li><strong>Proactive Protection:</strong> By predicting wildfire risks up to 10 days in advance, we empower
                  you to take proactive measures to safeguard your assets.</li>
              </ul>
            </div>

            <div className="col-lg-12 col-md-12">
              <h4>Protect Your Future Today</h4>
              <p>Don’t wait until it’s too late. Visit Wildfire Watch to learn more and start using our advanced wildfire
                prediction tools. Predict. Protect. Preserve.</p>
              <p><strong>Contact Us:</strong> For more information or support, reach out to our team at
                contact@planetguard.ai</p>
            </div>
            <div className="col-lg-12 col-md-12">
              <h4>Join Us in the Fight Against Wildfires</h4>
              <p>Together, we can make a difference. Stay informed, stay prepared, and help protect our communities from the
                devastating effects of wildfires.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrapper section-padding careers">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-12">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">Frequently Asked Questions</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                      aria-expanded="true" aria-controls="collapseOne">What data sources do you use for wildfire
                      prediction?</button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">We rely on a combination of geospatial satellite imagery, historical
                      wildfire data, present and predicted weather parameters, Digital Elevation Models (DEM), and land
                      cover information. These sources allow us to create highly accurate predictive models and assess
                      wildfire risk with precision.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">How accurate are your
                      wildfire predictions?</button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Our predictive models utilize the latest advancements in technology and data
                      analysis to provide accurate forecasts. While no prediction system can guarantee 100% accuracy, we
                      strive to offer the most reliable and up-to-date information possible to help you make informed
                      decisions and mitigate risk effectively.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Can I customize
                      the app according to my specific needs?</button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Absolutely! Our platform is designed to be flexible and adaptable to various
                      user requirements. Whether you're a homeowner, land manager, or emergency responder, you can customize
                      the app to focus on the parameters and areas of interest most relevant to you.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How far in advance
                      can I access wildfire predictions?</button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Our system provides forecasts up to 10 days in advance, giving you ample
                      time to prepare and take proactive measures. By leveraging historical data and real-time weather
                      updates, we strive to offer reliable predictions for an extended forecast period.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Is my personal data
                      secure?</button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">We take data privacy and security seriously. All personal information
                      provided to us is encrypted and stored securely in compliance with industry standards. We prioritize
                      the confidentiality and integrity of your data to ensure your peace of mind while using our platform.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Can I receive alerts
                      for wildfire threats in my area?</button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Yes, our platform offers real-time alerts for wildfire threats based on your
                      location and customized preferences. You can choose to receive notifications via email, SMS, or push
                      notifications on your mobile device to stay informed about potential risks in your area.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">How can I get
                      support if I encounter any issues?</button>
                  </h2>
                  <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Our dedicated support team is available to assist you with any questions or
                      concerns you may have. You can reach out to us via email at <a href="mailto:contact@planetguard.ai">contact@planetguard.ai</a> or through our
                      online contact form. We're here to ensure that you have a seamless and positive experience with our
                      platform.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">Can I access
                      historical wildfire data for my area?</button>
                  </h2>
                  <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Yes, our platform provides access to historical wildfire data, allowing you
                      to analyze past events and trends in your area. This information can be valuable for risk assessment,
                      planning, and decision-making.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">Do you offer
                      services for businesses and organizations?</button>
                  </h2>
                  <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Absolutely! We offer tailored solutions for businesses, organizations, and
                      government agencies to help them better understand and mitigate wildfire risk. Whether you're managing
                      a large property portfolio or overseeing emergency response efforts, our platform can provide valuable
                      insights and support.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">What sets your
                      wildfire prediction platform apart from others?</button>
                  </h2>
                  <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">Our platform stands out for its advanced technology, high-resolution data,
                      and user-friendly interface. We're committed to providing the most accurate and reliable wildfire
                      predictions possible, backed by scientific expertise and continuous innovation.</div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">Have More
                      Questions?</button>
                  </h2>
                  <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">If you have any additional questions or need further assistance, please
                      don't hesitate to contact us. Our team is committed to providing you with the information and support
                      you need to effectively manage wildfire risk and protect your assets.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default WildFire