import React from 'react'
import { Link } from 'react-router-dom'

import logoWhite from '../assets/images/logo-white.png'

const Top = () => {
  return (
    <div className="hamburger-area">
      <div className="hamburger_bg"></div>
      <div className="hamburger_wrapper">
        <div className="hamburger_top d-flex align-items-center justify-content-between">
          <div className="hamburger_logo">
            <Link to="/"><img src={logoWhite} alt="LOGO" /></Link>
          </div>
          <div className="hamburger_close">
            <button className="hamburger_close_btn"><i className="fa-thin fa-times"></i></button>
          </div>
        </div>

        <div className="hamburger_menu">
          <div className="mobile_menu"></div>
        </div>

        <div className="hamburger_bottom">
          <div className="contact_info">
            <h4 className="title">Contact Info</h4>

            <ul className="info_list">
              <li className="info_item">
                <div className="item_inner  d-flex align-items-center gap-2">
                  <div className="icon">
                    <a target="_blank" href="https://maps.google.com" rel="noreferrer">
                      <i className="fal fa-map-marker-alt"></i>
                    </a>
                  </div>
                  <div className="text">
                    <a target="_blank" href="https://maps.google.com" rel="noreferrer">
                      Annasandrapalya, Vimanapura, Bengaluru, Karnataka, India
                    </a>
                  </div>
                </div>
              </li>
              <li className="info_item">
                <div className="item_inner d-flex align-items-center gap-2">
                  <div className="icon">
                    <a target="_blank" href="tel:+778-779-1501" rel="noreferrer">
                      <i className="fal fa-phone"></i>
                    </a>
                  </div>
                  <div className="text">
                    <a target="_blank" href="tel:+919113867704" rel="noreferrer">
                      +91 9113867704
                    </a>
                  </div>
                </div>
              </li>
              <li className="info_item">
                <div className="item_inner d-flex align-items-center gap-2">
                  <div className="icon">
                    <a target="_blank" href="mailto:contact@planetguard.ai" rel="noreferrer">
                      <i className="fal fa-envelope"></i>
                    </a>
                  </div>
                  <div className="text">
                    <a target="_blank" href="mailto:contact@planetguard.ai" rel="noreferrer">
                      contact@planetguard.ai
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="socials">
            <ul>
              <li><a target="_blank" href="https://www.linkedin.com/company/planetguard" rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Top
