import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'

import app1 from '../assets/images/apps1.png'
import app2 from '../assets/images/apps2.png'
import app3 from '../assets/images/apps3.jpg'
import { Link } from 'react-router-dom'


const Apps = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Apps - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Apps" />

      <section className="apps-panel section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="apps relative">
                <Link to={'/wildfire'}><img src={app1} alt="Wildfire" /></Link>
                <h5><Link to={'/wildfire'}>Wildfire Prediction</Link></h5>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="apps relative">
                <Link to={'/agridoctor'}><img src={app2} alt="AgriDoctor" /></Link>
                <h5><Link to={'/agridoctor'}>Agri Doctor</Link></h5>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="apps relative">
                <Link to={'/landcover'}><img src={app3} alt="LandCover" /></Link>
                <h5><Link to={'/landcover'}>Landuse Landcover</Link></h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Apps