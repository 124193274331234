import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'

import agriDoctor from '../assets/images/Agri-Doctor.jpeg'


const AgriDoctor = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Agri Doctor - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Agri Doctor" />

      <section className="about-wrapper section-padding careers">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-6 mission">
              <img src={agriDoctor} alt="" className="mb-3" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">About AgriDoctor</h2>
              </div>
              <p>Welcome to AgriDoctor, your trusted partner in advanced agricultural solutions. We are dedicated to
                empowering farmers with cutting-edge technology to ensure healthier crops and higher yields. Our mission is
                to revolutionize farming practices by providing precise, real-time information about plant diseases and
                pests, enabling farmers to make informed decisions and protect their valuable crops.</p>
              <p>At AgriDoctor, we understand the challenges that farmers face in maintaining crop health. Our innovative
                app uses state-of-the-art technology, including artificial intelligence and machine learning, to diagnose
                plant diseases and detect pests accurately. With just a few clicks, farmers can access detailed information,
                actionable insights, and expert recommendations tailored to their specific needs.</p>

              <h4>Why Choose AgriDoctor?</h4>
              <p><strong>Precision:</strong> High accuracy in disease and pest detection ensures maximum crop yield.
                Leveraging advanced AI algorithms, our app quickly and precisely identifies plant diseases and pest</p>
              <p><strong>Convenience:</strong> Easy-to-use app accessible on Android smartphone/</p>
              <p><strong>Expert Guidance:</strong> Backed by a team of agricultural scientists and tech experts.</p>
              <p><strong>Support:</strong> Comprehensive support and resources to help farmers at every step.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrapper about-4 section-padding overflow-hidden bg-light careers">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-12">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">Frequently Asked Questions</h2>
              </div>
              <h5>General Questions</h5>
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                      aria-expanded="true" aria-controls="collapseOne">Q1: What is AgriDoctor?</button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A1: AgriDoctor is a digital platform designed to help farmers and gardeners
                      identify plant diseases quickly and accurately using advanced image recognition technology.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Q2: How does
                      AgriDoctor work?</button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A2: AgriDoctor works by analyzing uploaded images of plants for signs of
                      disease. Using advanced machine learning algorithms, the system can identify potential diseases and
                      provide recommendations for treatment.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Q3: Is AgriDoctor
                      free to use?</button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A3: Yes, AgriDoctor offers a basic free version for all users. There may be
                      premium features available for advanced diagnostics and additional support.</div>
                  </div>
                </div>
                <h5 className="mt-5">Using the System</h5>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Q4: How do I upload
                      an image to AgriDoctor?</button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A4: To upload an image, go to the Disease Recognition page, click on the
                      "Upload Image" button, and select the image of your plant from your device.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Q5: What types of
                      images are best for analysis?</button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A5: Clear, high-resolution images showing the affected part of the plant
                      (e.g., leaves, stems, fruits) are best for accurate analysis. Ensure the plant is well-lit and the
                      symptoms are visible.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Q6: How long does it
                      take to get results?</button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A6: The analysis process is fast, typically providing results within seconds
                      of image upload.</div>
                  </div>
                </div>
                <h5 className="mt-5">Accuracy and Reliability</h5>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">Q7: How accurate
                      is AgriDoctor?</button>
                  </h2>
                  <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A7: AgriDoctor uses state-of-the-art machine learning techniques to provide
                      highly accurate disease detection. However, no system is infallible, and results should be used in
                      conjunction with expert advice.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">Q8: Can AgriDoctor
                      detect all plant diseases?</button>
                  </h2>
                  <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A8: While AgriDoctor can identify a wide range of common plant diseases, it
                      may not cover every possible disease. Continuous updates are made to include more diseases and improve
                      accuracy.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">Q9: What should I do
                      if I get uncertain results?</button>
                  </h2>
                  <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A9: If results are uncertain or unclear, it is recommended to consult a
                      local agricultural expert or extension service for further diagnosis and advice.</div>
                  </div>
                </div>
                <h5 className="mt-5">Supported Crops</h5>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">Q10: Which crops are
                      supported by AgriDoctor?</button>
                  </h2>
                  <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A10: AgriDoctor supports a variety of crops including:
                      <ul>
                        <li><strong>Vegetables:</strong> Chilli, Okra, Tomato, Potato, Brinjal, Pumpkin, Onion, Bitter
                          Gourd, Sweet Gourd (Nenua), Pointed Gourd, Capsicum, Cucumber, Cabbage/Cauliflower, Coriander,
                          Peas, Beans, Moringa, Cowpea, Kantola, Radish, Beetroot.</li>
                        <li><strong>Fruits:</strong> Mango, Papaya, Litchi, Banana, Guava, Lemon, Watermelon, Strawberry,
                          Jackfruit, Carrot.</li>
                        <li><strong>Grains:</strong> Paddy, Wheat, Maize, Sugarcane, Mustard, Pulses, Millets.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h5 className="mt-5">Technical Issues</h5>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">Q11: I am having
                      trouble uploading an image. What should I do?</button>
                  </h2>
                  <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A11: Ensure your image meets the file size and format requirements (e.g.,
                      JPG, PNG). If the problem persists, try refreshing the page or using a different browser. Contact
                      support if the issue continues.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">Q12: What should
                      I do if the system is not working properly?</button>
                  </h2>
                  <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A12: If you experience technical difficulties, please contact our support
                      team through the Contact Us page. Provide detailed information about the issue to help us resolve it
                      quickly.</div>
                  </div>
                </div>
                <h5 className="mt-5">Privacy and Security</h5>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">Q13: Is my
                      data secure with AgriDoctor?</button>
                  </h2>
                  <div id="collapseThirteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A13: Yes, we prioritize your privacy and data security. Uploaded images and
                      data are handled with strict confidentiality and are not shared with third parties without your
                      consent.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseForteen" aria-expanded="false" aria-controls="collapseForteen">Q14: How is my
                      data used by AgriDoctor?</button>
                  </h2>
                  <div id="collapseForteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A14: Your data is used solely for the purpose of analyzing plant health and
                      improving our algorithms. Aggregate data may be used for research and development to enhance the
                      service.</div>
                  </div>
                </div>
                <h5 className="mt-5">Additional Support</h5>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">Q15: Can I get
                      expert advice through AgriDoctor?</button>
                  </h2>
                  <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A15: While AgriDoctor provides automated disease detection, we also offer
                      premium services that include access to agricultural experts for personalized advice.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">Q16: How can I
                      provide feedback about AgriDoctor?</button>
                  </h2>
                  <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A16: We welcome your feedback! Please use the feedback form on our Contact
                      Us page to share your thoughts and suggestions.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">Q17: How
                      can I stay updated with the latest from AgriDoctor?</button>
                  </h2>
                  <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">A17: Follow us on social media and subscribe to our newsletter for the
                      latest updates, tips, and news related to plant health and agriculture.</div>
                  </div>
                </div>
              </div>
              <p>This FAQ section should address common questions and provide useful information to help users navigate and
                make the most out of AgriDoctor.</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default AgriDoctor