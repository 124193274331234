import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'

import landCover from '../assets/images/Land-Cover.jpg'


const LandCover = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Land Cover - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Land Cover" />

      <div className="about-wrapper section-padding careers">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 mission">
              <img src={landCover} alt="" className="mb-3" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">Land Cover Classification</h2>
              </div>
              <h4>Overview:</h4>
              <p>Automatic Land Cover Classification is a cutting-edge online product designed by Planet Guard to revolutionise geographic analysis by automating the process of categorising land cover types. Leveraging advanced machine learning algorithms and remote sensing technology, this innovative tool offers a seamless solution for government agencies and researchers working in environmental science, urban planning, agriculture, forestry, and other related fields.</p>

              <h4>Classification Options:</h4>
              <p>Tree cover, Shrubland (Rangeland), Grassland, Cropland, Built-up, Bare/sparse vegetation, Snow and ice, Water bodies, Wetland, Mangroves, Moss and lichen</p>

              <h4>Definition of classes</h4>
              <p><strong>Tree cover:</strong> This class includes any geographic area dominated by trees with a cover of 10% or more. Other land cover classes (shrubs and/or herbs in the understorey, built-up, permanent water bodies) can be present below the canopy, even with a density higher than trees. Areas planted with trees for afforestation purposes and plantations (e.g. oil palm, olive trees) are included in this class. This class also includes tree covered areas seasonally or permanently flooded with fresh water except for mangroves.</p>
              <p><strong>Shrubland (Rangeland):</strong> This class includes any geographic area dominated by natural shrubs having a cover of 10% or more. Shrubs are defined as woody perennial plants with persistent and woody stems and without any defined main stem being less than 5 m tall. Trees can be present in scattered form if their cover is less than 10%. Herbaceous plants can also be present at any density. The shrub foliage can be either evergreen or deciduous.</p>
            </div>
            <div className="col-lg-12 col-md-12 mt-3">
              <p><strong>Grassland:</strong> This class includes any geographic area dominated by natural herbaceous plants (Plants without persistent stem or shoots above ground and lacking definite firm structure): (grasslands, prairies, steppes, savannahs, pastures) with a cover of 10% or more, irrespective of different human and/or animal activities, such as: grazing, selective fire management etc. Woody plants (trees and/or shrubs) can be present assuming their cover is less than 10%. It may also contain uncultivated cropland areas (without harvest/ bare soil period) in the reference year</p>
              <p><strong>Cropland:</strong> Land covered with annual cropland that is sowed/planted and harvestable at least once within the 12 months after the sowing/planting date. The annual cropland produces an herbaceous cover and is sometimes combined with some tree or woody vegetation. Note that perennial woody crops will be classified as the appropriate tree cover or shrub land cover type. Greenhouses are considered as built-up</p>
              <p><strong>Built-up:</strong> Land covered by buildings, roads and other man-made structures such as
                railroads. Buildings include both residential and industrial buildings. Urban green (parks, sport
                facilities) is not included in this class. Waste dump deposits and extraction sites are considered as bare.
              </p>
              <p><strong>Bare/sparse vegetation:</strong> Lands with exposed soil, sand, or rocks and never has more than 10
                % vegetated cover during any time of the year</p>
              <p><strong>Snow and ice:</strong> This class includes any geographic area covered by snow or glaciers
                persistently</p>
              <p><strong>Water bodies:</strong> This class includes any geographic area covered for most of the year (more
                than 9 months) by water bodies: lakes, reservoirs, and rivers. Can be either fresh or salt-water bodies. In
                some cases the water can be frozen for part of the year (less than 9 months). </p>
              <p><strong>Wetland:</strong> Land dominated by natural herbaceous vegetation (cover of 10% or more) that is
                permanently or regularly flooded by fresh, brackish or salt water. It excludes unvegetated sediment, swamp
                forests (classified as tree cover) and mangroves)</p>
              <p><strong>Mangroves:</strong> Taxonomically diverse, salt-tolerant tree and other plant species which thrive
                in intertidal zones of sheltered tropical shores, "overwash" islands, and estuaries</p>
              <p><strong>Moss and lichen:</strong> Land covered with lichens and/or mosses. Lichens are composite organisms
                formed from the symbiotic association of fungi and algae. Mosses contain photo-autotrophic land plants
                without true leaves, stems, roots but with leaf-and stem like organs.</p>
            </div>
          </div>
        </div>
      </div>

      <section className="about-wrapper about-4 section-padding overflow-hidden bg-light careers">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section__heading text-start wow fadeInUp " data-wow-delay="300ms">
                <h2 className="section_title">Key Features</h2>
              </div>
              <p><strong>Automated Classification:</strong> The heart of the product lies in its ability to classify land
                cover types from satellite or aerial imagery automatically. Gone are the days of manual interpretation and
                time-consuming pixel-by-pixel analysis. Our AI-driven system swiftly processes vast amounts of data,
                accurately identifying and categorising land cover features such as forests, water bodies, urban areas,
                agricultural land, and more</p>
              <p><strong> Automated Report Generation:</strong> Based on data provided, statistical changes in classes
                throughout the year.</p>
              <p><strong>High Accuracy:</strong> Backed by state-of-the-art machine learning models, our classification
                algorithms ensure high accuracy levels, minimising errors and discrepancies. Through continuous refinement
                and validation, we strive to deliver results that meet the highest precision standards, enabling users to
                confidently make informed decisions.</p>
              <p><strong> Customization Options:</strong> Recognizing the diverse needs of our users, the product offers
                customization options to tailor the classification process according to specific requirements. Users can
                adjust parameters, select desired land cover classes, and input requirement data like district and states or
                select a custom region on a map.</p>
              <p><strong>Scalability and Efficiency:</strong> Whether analysing small-scale regions or entire states, our
                platform offers scalability to handle projects of any size. By automating repetitive tasks and streamlining
                workflows, users can significantly increase efficiency and productivity, allowing them to focus on more
                strategic aspects of their projects.</p>
              <p><strong>Accessibility and Affordability:</strong> As a cloud-based solution, Automatic Land Cover
                Classification ensures accessibility from anywhere with an internet connection, eliminating the need for
                costly hardware investments or specialised software installations. Our flexible subscription model offers
                affordable pricing plans tailored to suit the needs of individuals, research institutions, government
                agencies, and businesses alike.</p>

              <h4>Use case</h4>
              <h6><strong>Environmental Monitoring:</strong></h6>
              <ul>
                <li>Assessing changes in forest cover, deforestation rates, and habitat fragmentation.</li>
                <li>Monitoring wetland dynamics, shoreline changes, and water body encroachment for effective water resource
                  management.</li>
                <li>Tracking land degradation, desertification, and urban expansion to support sustainable land use
                  planning.</li>
              </ul>

              <h6><strong>Agricultural Management:</strong></h6>
              <ul>
                <li>Mapping agricultural land cover changes, such as expansion or conversion of farmland, to support
                  policy-making and agricultural land management strategies.</li>
              </ul>

              <h6><strong>Urban Planning and Development:</strong></h6>
              <ul>
                <li>Mapping urban sprawl, land use zoning, and built-up areas to support urban planning, infrastructure
                  development, and transportation network optimisation.</li>
                <li>Assessing land suitability for urban expansion, identifying brownfield sites for redevelopment, and
                  analysing impervious surface coverage for urban heat island mitigation.</li>
                <li>Monitoring changes in land cover due to urbanisation, population growth, and land use policy
                  interventions to inform sustainable urban development strategies. This can be used to better plan for
                  smart cities.</li>
              </ul>

              <h6><strong>Natural Resource Management:</strong></h6>
              <ul>
                <li>Identifying mining activities, logging operations, and extractive industries' impacts on land cover and
                  landscape changes to guide sustainable resource management practices.</li>
                <li>Mapping protected areas, conservation zones, and biodiversity hotspots to prioritise conservation
                  efforts, enhance habitat connectivity, and safeguard ecosystems.</li>
                <li>Monitoring land cover changes in sensitive ecosystems, such as coastal zones and mangroves to support
                  climate adaptation and resilience-building initiatives.</li>
              </ul>

              <h6><strong>Disaster Risk Management:</strong></h6>
              <ul>
                <li>Assessing vulnerability to natural hazards, such as floods, landslides, and wildfires, by mapping land
                  cover types, terrain characteristics, and exposure levels.</li>
                <li>Monitoring post-disaster recovery and ecosystem regeneration processes by analysing changes in land
                  cover, vegetation dynamics, and landscape resilience.</li>
              </ul>

              <div className="author-content align-items-center d-none" data-aos="fade-up" data-aos-duration="1000">
                <a href="https://planetguard.ai" className="tj-btn-primary style-1">Get our app from google play store <i
                  className="fa-light fa-arrow-right-long"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default LandCover