import React, { useEffect } from 'react'

import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'


const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Careers - Planet Guard"
  }, [])

  return (
    <>
      <Header />
      <Breadcrumb name="Careers" />

      <section className="section-padding bg-cover bg-light careers">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section__heading wow fadeInUp " data-wow-delay="0.4s">
                <h2 className="section_title">Open Position</h2>

                <p><span><strong>Job Title: Senior Open Source Web GIS Developer</strong></span></p>
                <p><span><strong>Location:</strong> Remote</span></p>
                <p><span><strong>Company:</strong> Planet Guard Solutions</span></p>
                <p><span><strong>About Us: </strong>&nbsp;&nbsp;Planet Guard is a groundbreaking startup at the forefront of
                  environmental protection. We harness the power of cutting-edge technologies such as Artificial
                  Intelligence (AI), Machine Learning (ML), Geographic Information Systems (GIS), and Remote Sensing to
                  revolutionize the way we safeguard our planet's natural resources. Join us in our mission to create a
                  sustainable future for generations to come.&nbsp;</span></p>
                <p><span><strong>Job Description: </strong>We are seeking a highly skilled and experienced Senior Open
                  Source Web GIS Developer to join our dynamic team. The ideal candidate will have 8-10 years of hands-on
                  experience in developing web-based GIS applications using open source technologies. The role will
                  involve collaborating with cross-functional teams to design, develop, and maintain innovative GIS
                  solutions that address real-world environmental challenges.</span></p>
                <p><span><strong>Responsibilities:</strong></span></p>
                <ul>
                  <li>Designing and developing scalable, high-performance web GIS applications using open source frameworks
                    such as OpenLayers, Leaflet, MapServer, GeoServer, etc.</li>
                  <li>Integrating geospatial data from various sources and formats (e.g., shapefiles, GeoJSON, WMS, WFS)
                    into web applications.</li>
                  <li>Implementing advanced spatial analysis and visualization techniques to derive actionable insights from
                    geospatial data.</li>
                  <li>Collaborating with UX/UI designers to create intuitive and user-friendly interfaces for GIS
                    applications.</li>
                  <li>Writing clean, efficient, and well-documented code following industry best practices and coding
                    standards.</li>
                  <li>Conducting code reviews, debugging, and troubleshooting to ensure the reliability and performance of
                    GIS applications.</li>
                  <li>Keeping abreast of the latest developments in GIS technologies and contributing to the continuous
                    improvement of our GIS platform.</li>
                </ul>
                <p><span><strong>Requirements:</strong></span></p>
                <ul>
                  <li>Bachelor's or Master's degree in Computer Science, GIS, or a related field.</li>
                  <li>8-10 years of professional experience in software development, with a focus on web GIS applications.
                  </li>
                  <li>Proficiency in JavaScript, HTML, CSS, and experience with modern web development frameworks (e.g.,
                    React, Angular, Vue.js).</li>
                  <li>Strong knowledge of open source GIS technologies such as OpenLayers, Leaflet, MapServer, GeoServer,
                    PostGIS, etc.</li>
                  <li>Experience with geospatial databases (e.g., PostgreSQL/PostGIS, MongoDB) and spatial data processing
                    libraries.</li>
                  <li>Familiarity with GIS data formats, coordinate systems, and spatial data analysis techniques.</li>
                  <li>Excellent problem-solving skills and the ability to work independently or as part of a team in a
                    fast-paced environment.</li>
                  <li>Strong communication skills and the ability to effectively collaborate with stakeholders across
                    different departments.</li>
                  <li>A proactive attitude towards learning new technologies and a passion for leveraging GIS for
                    environmental conservation.</li>
                </ul>
                <p><span><strong>How to Apply: </strong>Please email your resume to <a href="mailto:contact@planetguard.ai"
                  target="_blank" rel="noreferrer">contact@planetguard.ai</a> along with a link to your GitHub profile showcasing
                  relevant projects and code samples. In your email, briefly highlight your experience and achievements in
                  web GIS development. We look forward to hearing from you!</span></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Careers