import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './App.css'

import Home from './screens/Home'
import AgriGuard from './screens/AgriGuard'
import AgriGuardApp from './screens/AgriGuardApp'
import AgriDoctor from './screens/AgriDoctor'
import AgriDoctorApp from './screens/AgriDoctorApp'
import LandCover from './screens/LandCover'
import LandCoverApp from './screens/LandCoverApp'
import WildFire from './screens/WildFire'
import WildFireApp from './screens/WildFireApp'
import Apps from './screens/Apps'
import Careers from './screens/Careers'
import Blog from './screens/Blog'
import BlogCategory from './screens/BlogCategory'
import BlogDetails from './screens/BlogDetails'
import ContactUs from './screens/ContactUs'

const App = () => {
  return (
    <Router>
      <div className="body-overlay"></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/agri-guard' element={<AgriGuard />} />
        <Route path='/agriguard' element={<AgriGuardApp />} />
        <Route path='/agri-doctor' element={<AgriDoctor />} />
        <Route path='/agridoctor' element={<AgriDoctorApp />} />
        <Route path='/land-cover' element={<LandCover />} />
        <Route path='/landcover' element={<LandCoverApp />} />
        <Route path='/wild-fire' element={<WildFire />} />
        <Route path='/wildfire' element={<WildFireApp />} />
        <Route path="/apps" element={<Apps />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/category/:category" element={<BlogCategory />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </Router>
  )
}

export default App
