import React from 'react'
import { Link } from 'react-router-dom'


const Breadcrumb = (props) => {
  return (
    <section className="breadcrumb-wrapper hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb-content">
              <h2 className="title  wow fadeInUp " data-wow-delay="300ms">{props.name}</h2>
              <div className="breadcrumb__link">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>{props.name}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Breadcrumb