import React from 'react'
import { Link } from 'react-router-dom'

import whiteLogo from '../assets/images/logo-white.png'
import footer1 from '../assets/images/footer-1.svg'
import footer2 from '../assets/images/footer-2.svg'

const Footer = () => {
  return (
    <footer className="footer-3 overflow-hidden">
      <div className="footer__middle footer3_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer__widget footer3_col_1">
                <div className="footer_info">
                  <Link to="/" className="footer__logo_1"><img src={whiteLogo} alt="logo" /></Link>
                  <p>PlanetGuard.ai is a pioneering force in the realm of environmental conservation and resilience.</p>
                </div>
                <div className="footer__time">
                  <h4 className="time__title">Working Hours</h4>
                  <ul className="time_show">
                    <li>Mon - Sat: 09AM - 08PM</li>
                    <li>Sunday: Close</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer__widget footer3_col_2">
                <h4 className="widget__title">Quick Links</h4>
                <ul className="footer-widget__explore-list">
                  <li><Link to="/"><i className="fa fa-angle-right"></i> Home</Link></li>
                  <li><Link to="/agri-guard"><i className="fa fa-angle-right"></i> Agri Guard</Link></li>
                  <li><Link to="/agri-doctor"><i className="fa fa-angle-right"></i> Agri Doctor</Link></li>
                  <li><Link to="/land-cover"><i className="fa fa-angle-right"></i> Land Cover</Link></li>
                  <li><Link to="/wild-fire"><i className="fa fa-angle-right"></i> Wildfire</Link></li>
                  <li><Link to="/apps"><i className="fa fa-angle-right"></i> Apps</Link></li>
                  <li><Link to="/careers"><i className="fa fa-angle-right"></i> Careers</Link></li>
                  <li><Link to="/blog"><i className="fa fa-angle-right"></i> Blog</Link></li>
                  <li><Link to="/contact-us"><i className="fa fa-angle-right"></i> Contact Us</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer__widget footer3_col_3">
                <h3 className="widget__title">Get in touch</h3>

                <div className="footer__contact-list">
                  <div className="info-contact">
                    <Link to="/contact">
                      <i className="flaticon-placeholder"></i>
                      <h6>Address</h6>
                      <p>Salarpuria Serinity, 7th sector, HSR Layout, Bengaluru, Karnataka 560102</p>
                    </Link>
                  </div>
                  <div className="info-contact">
                    <a href="tel:+919113867704">
                      <i className="flaticon-call"></i>
                      <h6>Phone</h6>
                      <p>+91 9113867704</p>
                    </a>
                  </div>
                  <div className="info-contact">
                    <a href="mailto:contact@planetguard.ai">
                      <i className="flaticon-envelope"></i>
                      <h6>Email</h6>
                      <p>contact@planetguard.ai</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer__widget footer3_col_4">
                <h4 className="widget__title">Newsletter</h4>
                <div className="footer__form">
                  <div className="footer__form_dec">
                    <p>Sign up to hear from us about specials, sales, and events.</p>
                  </div>
                  <form action="https://planetguard.us17.list-manage.com/subscribe/post?u=da22a802266fd6ee4706af662&amp;id=3abc7c48a9&amp;f_id=00ed43e3f0" method="POST">
                    <input type="email" className="form-control" name="EMAIL" placeholder="Email Address" required />
                    <div className="input-group-prepend mt-3">
                      <button type="submit" name="subscribe" className="input-group-text mt-20 tj-btn-primary style-3">
                        Subscribe <i className="fa-light fa-arrow-right-long"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="footer__copy_write">
            <p className="copy__right">Copyright &copy; Planet Guard 2024. All Rights Reserved.</p>
            <div className="link d-none">Developed By: <a href="/" target="_blank" rel="noreferrer">Projecsia</a></div>
          </div>
        </div>
      </div>
      <div className="footer-shape"><img src={footer1} alt="" /></div>
      <div className="footer-right-shape"><img src={footer2} alt="" /></div>
    </footer>
  )
}

export default Footer
