import React from 'react'

import vidmp4 from '../assets/videos/home-video.mp4'

const Banner = () => {
  return (
    <section className="hero-wrapper hero-1">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-md-6 col-lg-6">
            <div className="hero-content text-start">
              <h3 className="hero_sub_title text-white">Join Us in Building a</h3>
              <h1 className="hero-title text-white">Sustainable, Eco-Friendly, Secure World from Space</h1>
              <p className="wow fadeInRight " data-wow-delay="1s">Begin today by transforming your infrastructure to be climate-resilient and sustainable</p>
            </div>
          </div>
          <div className="video-bg">
            <video id="dfca12d3-becf-bf17-a9f4-d4551d5aa722-video" autoPlay loop muted playsInline data-wf-ignore="true" data-object-fit="cover">
              <source src={vidmp4} data-wf-ignore="true" />
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
