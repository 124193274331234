import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import Top from './Top'

import logo from '../assets/images/logo.png'

const Header = () => {
  const location = useLocation()

  return (
    <>
      <Top />

      <header className="header-3 is-sticky">
        <div className="header__top d-none d-xl-block">
          <div className="container">
            <div className="header__top_widget">
              <div className="top-left">
                <p>
                  <span>
                    <i className="flaticon-envelope"></i>
                    <a href="mailto:contact@planetguard.ai">contact@planetguard.ai</a>
                  </span>
                  <span>
                    <i className="flaticon-call"></i>
                    <a href="tel:+919113867704">+91 9113867704</a>
                  </span>
                </p>
              </div>
              <div className="top-right">
                <div className="top__ social">
                  <a target="_blank" href="https://www.linkedin.com/company/planetguard" rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                </div>
                <NavLink to="/contact-us" className="tj-btn-primary style-3">Get a Demo</NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="main__header">
          <div className="container">
            <div className="row  align-items-center">
              <div className=" col-lg-3 col-md-4 col-5">
                <div className="logo">
                  <NavLink to="/"><img src={logo} alt="logo" /></NavLink>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-7 text-end">
                <div className="main-menu">
                  <nav id="mobile-menu">
                    <ul className="d-none d-lg-block">
                      <li><NavLink to="/">Home</NavLink></li>
                      <li className="has-dropdown">
                        <a href="#" className={['/agri-guard', '/agri-doctor', '/land-cover', '/wild-fire'].indexOf(location.pathname) !== -1 ? 'active' : ''}>Products</a>
                        <ul className="sub-menu">
                          <li className="has-dropdown">
                            <a href="#" className={['/agri-guard', '/agri-doctor'].indexOf(location.pathname) !== -1 ? 'active' : ''}>Agri Guard</a>
                            <ul className="sub-menu">
                              <li><NavLink to="/agri-guard">Agri Guard</NavLink></li>
                              <li><NavLink to="/agri-doctor">Agri Doctor</NavLink></li>
                            </ul>
                          </li>
                          <li className="has-dropdown">
                            <a href="#" className={['/land-cover', '/wild-fire'].indexOf(location.pathname) !== -1 ? 'active' : ''}>Climate Guard</a>
                            <ul className="sub-menu">
                              <li><NavLink to="/land-cover">Land Cover</NavLink></li>
                              <li><NavLink to="/wild-fire">Wild Fire</NavLink></li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li><NavLink to="/apps">Apps</NavLink></li>
                      <li><NavLink to="/careers">Careers</NavLink></li>
                      <li><NavLink to="/blog">Blog</NavLink></li>
                      <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                    </ul>
                  </nav>

                  <div className="header-contact d-flex justify-content-start align-items-center">
                    <div id="hamburger" className="d-lg-none">
                      <i className="fa-light fa-bars-sort"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
