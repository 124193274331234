import React, { useEffect, useRef } from 'react'
import L from 'leaflet'
import 'leaflet-draw'
import 'leaflet-geoserver-request'

import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'


const MapWildfire = ({ center, schools, facilities }) => {
  const mapRef = useRef(null)

  useEffect(() => {
    const mapContainer = mapRef.current
    if (mapContainer && !mapContainer.mapInstance) {
      const map = L.map('map').setView(center, 6)
      mapRef.current.mapInstance = map

      const worldLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
      worldLayer.addTo(map)

      const wmsLayer = L.Geoserver.wms('https://planetguard.ai/geoserver/wms', {
        layers: 'Wildfire',
        transparent: true
      })
      wmsLayer.addTo(map)

      const schoolMarkers = []
      const schoolIcon = L.divIcon({
        className: 'school-icon'
      })
      schools.forEach(school => {
        const schoolMarker = L.marker([school.latitude, school.longitude], { icon: schoolIcon })
        schoolMarker.bindPopup(`<b>${school.name}</b>`).addTo(map)
        schoolMarker.bindTooltip(school.name).addTo(map)
        schoolMarkers.push(schoolMarker)
      })
      const schoolMarkerLayer = L.layerGroup(schoolMarkers)
      schoolMarkerLayer.addTo(map)

      const fireMarkers = []
      const fireIcon = L.divIcon({
        className: 'fire-icon'
      })
      facilities.forEach(facility => {
        const fireMarker = L.marker([facility.latitude, facility.longitude], { icon: fireIcon })
        fireMarker.bindPopup(`<b>${facility.name}</b>`).addTo(map)
        fireMarker.bindTooltip(facility.name).addTo(map)
        fireMarkers.push(fireMarker)
      })
      const fireMarkerLayer = L.layerGroup(fireMarkers)
      fireMarkerLayer.addTo(map)

      const drawnItems = new L.FeatureGroup()
      map.addLayer(drawnItems)

      const drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems
        },
        draw: {
          circle: true,
          marker: true,
          polygon: true,
          polyline: true,
          rectangle: true,
          circlemarker: true
        }
      })
      map.addControl(drawControl)

      map.on('draw:created', function (e) {
        const { layer } = e
        drawnItems.addLayer(layer)
      })

      const locationLayer = L.layerGroup().addTo(map)
      function onLocationFound(e) {
        const radius = e.accuracy / 2
        L.marker(e.latlng).addTo(map).bindPopup(`You are within ${radius} meters from this point`).openPopup()
        L.circle(e.latlng, radius).addTo(locationLayer)
      }

      function onLocationError(e) {
        alert(e.message)
      }

      map.on('locationfound', onLocationFound)
      map.on('locationerror', onLocationError)

      const LocateControl = L.Control.extend({
        options: {
          position: 'topright'
        },
        onAdd: function (map) {
          const container = L.DomUtil.create('div', 'leaflet-bar')
          const button = L.DomUtil.create('button', '', container)
          button.innerHTML = '📍'

          L.DomEvent.on(button, 'click', function () {
            map.locate({ setView: true, maxZoom: 16 })
          })

          return container
        }
      })
      map.addControl(new LocateControl())

      const Legend = L.Control.extend({
        options: {
          position: 'bottomleft'
        },
        onAdd: function (map) {
          const div = L.DomUtil.create('div', 'legend')
          div.innerHTML += '<small><i style="background-color:brown"></i> High Risk</small>'
          div.innerHTML += '<small><i style="background-color:yellow"></i> Medium Risk</small>'
          div.innerHTML += '<small><i style="background-color:green"></i> Low Risk</small>'
          return div
        }
      })
      map.addControl(new Legend())

      const baseLayers = {
        'World Map': worldLayer
      }
      const overlays = {
        'WMS Layer': wmsLayer,
        'Schools': schoolMarkerLayer,
        'FireDept': fireMarkerLayer,
        'DrawnItems': drawnItems,
        'MyLocation': locationLayer
      }
      L.control.layers(baseLayers, overlays).addTo(map);
    }

    return () => {
      if (mapContainer?.mapInstance) {
        mapContainer.mapInstance.remove()
        mapContainer.mapInstance = null
      }
    }
  }, [center, schools, facilities])

  return (
    <div id="map" ref={mapRef} style={{ height: '100vh' }} />
  )
}

export default MapWildfire
